<template>
  <div id="diagram-enterprise" class="part-component part-diagram">
    <div class="facts-list">
      <div
        class="
          facts-box
          layout-row layout-align-start-center
          parallax-item parallax-flex
          flex-initial
        "
        :class="`facts-box-${fact.color}`"
        v-for="(fact, i) in diagramData"
        :key="i"
        :data-depth="2.5 - i * 0.2"
      >
        <div
          class="
            facts-icon
            layout-column layout-align-center-center
            parallax-item parallax-flex
          "
          data-depth="0.2"
        >
          <i :class="[`ai-${fact.icon_name}`, fact.color]"></i>
        </div>
        <div class="facts-text">
          <span v-html="fact.text"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { partComponent } from "@/mixins/core.js";

export default {
  name: "DiagramEnterprise",
  components: {},
  mixins: [partComponent],
  props: ["show", "diagramData"],
  data() {
    return {};
  },
  methods: {
    createTimeline() {
      const tl = new TimelineMax({
        paused: true,
      });

      tl.staggerFrom(
        ".facts-box",
        0.6,
        {
          autoAlpha: 0,
          x: "-=50",
          ease: Power2.easeOut,
        },
        0.15,
        0
      ).staggerFrom(
        ".facts-icon",
        0.6,
        {
          autoAlpha: 0,
          scale: 0,
          ease: Back.easeOut.config(1.5),
        },
        0.15,
        0
      );

      this.timeline = tl;
    },
  },
};
</script>

<style scoped>
.facts-list {
  font-size: 1rem;
}
.facts-box {
  margin: 0 0 1.2em;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.7);
  background: rgba(255, 255, 255, 0.65);
  padding: 0 0 0 0.8em;
}
.facts-icon {
  font-size: 3.515em;
}
.facts-icon i {
  line-height: 0.7;
}
.facts-text {
  max-width: 24.6em;
  margin-left: 1.2em;
  padding: 0.5em 0.7em;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/

@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1366px) {
}

@media screen and (max-width: 1330px) {
  #diagram-enterprise {
    max-width: 62.5em;
  }
  .facts-text {
    max-width: none;
  }
  .facts-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .facts-box {
    width: 50%;
  }
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 1023px) {
}

@media screen and (max-width: 991px) {
  #diagram-enterprise {
    max-width: 100%;
  }
  .facts-text {
    max-width: 24.6em;
  }
  .facts-list {
    flex-direction: column;
  }
  .facts-box {
    width: auto;
  }
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 639px) {
  .facts-list {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 520px) {
  .facts-text {
    padding-right: 0;
  }
}

@media screen and (max-width: 479px) {
}

@media screen and (max-width: 420px) {
  .facts-list {
    font-size: 11px;
  }
}

@media screen and (max-width: 390px) {
}
</style>
