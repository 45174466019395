<template>
  <div :id="viewCSSId" :class="viewCSSClasses" ref="scene">
    <div class="section-foreground container">
      <div class="rows layout-column layout-align-center-center">
        <div
          class="row row-1 layout-align-start-center layout-column col-padding"
        >
          <section-title
            :partNo="part.id"
            :partTitle="part.title"
            data-depth="2.5"
            class="text-right parallax-item parallax-relative single-line"
            :show="showTitle"
          ></section-title>
        </div>
        <div
          class="
            row row-2
            layout-row
            cols
            col-padding
            layout-align-center-start
          "
        >
          <div
            class="col-1 layout-column layout-align-start-center col-padding"
          >
            <lead
              id="lead-boxslider"
              :show="showQuote"
              class="parallax-item parallax-relative"
              data-depth="1.8"
              :heading="data.sliders.title"
              :timescale="1"
              :customHeadingTimeline="timelines.timelineLeadHeading"
            >
              {{ data.sliders.desc }}
            </lead>
            <box-slider
              :ink="data.images.inkWide"
              :show="showMisc"
              :data="data.sliders"
            ></box-slider>
          </div>
          <div class="col-2 layout-align-start-start layout-column">
            <lead
              id="lead-enterprise"
              :show="showQuote"
              class="parallax-item parallax-relative"
              data-depth="1.6"
              :timescale="1"
              >{{ data.meta.description }}</lead
            >

            <diagram-enterprise
              :show="showDiagram"
              :diagramData="data.controls.items"
            ></diagram-enterprise>
          </div>
        </div>
      </div>
    </div>

    <div class="container absolute ink-container flex z-index-0">
      <ink
        :customTimeline="timelines.timelineInk"
        :show="showInk"
        :image="data.images.ink"
      ></ink>
    </div>
    <div class="container absolute fluid zebras-container z-index-1">
      <decal
        id="zebras"
        class="absolute"
        :show="showBackground"
        :image="data.images.panorama"
        :parallaxDepth="1"
        :customTimeline="timelines.timelinePanorama"
      ></decal>
    </div>
    <div class="container fixed fluid man-container z-index-2">
      <decal
        id="man"
        :parallaxDepth="1.8"
        class="absolute"
        :show="showDecal"
        :image="data.images.decal"
        :customTimeline="timelines.timelineDecal"
      ></decal>
    </div>
    <modal-main
      v-for="modal in data.modals"
      :data="modal"
      :key="modal.name"
    ></modal-main>
  </div>
</template>

<script>
import { chapterPart } from "@/mixins/core.js";

import SectionTitle from "@/components/layout/SectionTitle.vue";
import Decal from "@/components/ui/Decal.vue";
import Ink from "@/components/ui/Ink.vue";
import Lead from "@/components/ui/Lead.vue";
import BoxSlider from "@/components/ui/BoxSlider.vue";

import DiagramEnterprise from "@/views/chapters/3/DiagramEnterprise.vue";

import ModalMain from "@/modals/ModalMain.vue";

export default {
  name: "Chapter-3-3",
  mixins: [chapterPart],
  components: {
    SectionTitle,
    Decal,
    Ink,
    Lead,
    BoxSlider,
    DiagramEnterprise,
    ModalMain,
  },
  data() {
    return {
      doParallax: true,
      itemsToShow: [
        ["showDelay", 200],
        ["showTitle", 500],
        ["showDecal", 100],
        ["showDiagram", 100],
        ["showMisc", 0],
        ["showBackground", 100],
        ["showQuote", 0],
        ["showLead", 200],
        ["showInk", 1000],
      ],
      timelines: {
        timelineInk: {
          duration: 0.7,
          animations: {
            scale: 1,
            x: "+=20%",
            ease: Power2.easeOut,
          },
        },
        timelineLeadHeading: {
          start: 0,
          duration: 0.7,
          animations: {
            autoAlpha: 0,
            ease: Power1.easeInOut,
          },
        },
        timelineDecal: {
          duration: 1.6,
          animations: {
            scale: 0.85,
            autoAlpha: 0,
            y: "+=7%",
            x: "-=7%",
            ease: Power3.easeInOut,
          },
        },
        timelinePanorama: {
          duration: 1,
          animations: {
            ease: Power1.easeOut,
            autoAlpha: 0,
            y: "+=50",
          },
        },
      },
    };
  },
  computed: {},
};
</script>

<style scoped>
.row-1 {
  margin-top: auto;
  width: 100%;
}
.row-2 {
  margin-bottom: auto;
  margin-left: 8%;
}
.col-1 {
  padding-left: 0;
}
.section-title {
  margin-right: 25rem;
}
#lead-boxslider {
  margin: 0 10.2rem 0 0;
}
/deep/ #lead-boxslider .lead-heading {
  margin: 0 0 0.25em;
}
#diagram-enterprise {
  margin-top: 2.2em;
}
.man-container {
  height: 100vh;
}
#man {
  bottom: -1rem;
  left: -5rem;
  z-index: 1;
}
/deep/ #man img {
  filter: drop-shadow(rgba(0, 0, 0, 0.3) -2rem 3rem 2.2rem);
  max-width: 29vw;
  max-height: 85vh;
}
#zebras {
  z-index: 0;
  width: 100%;
  margin: 0;
  bottom: -1rem;
  right: -0.2%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
/deep/ #zebras img {
  width: 100%;
  min-width: 1300px;
  height: auto;
  max-width: none;
}

.ink-container {
  mix-blend-mode: multiply;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.section-ink {
  opacity: 0.5;
  margin-right: 14%;
  margin-top: 8%;
}
/deep/ .section-ink img {
  max-width: 1150px;
  width: 100%;
  margin: 0;
  display: block;
  top: 0 !important;
  left: 0 !important;
}

/deep/ .box-slider {
  max-width: 750px;
}

/deep/ .slide-text {
  padding-left: 1em;
}

/deep/ .glide__bullets {
  padding: 0.9rem 0 1rem;
}

/deep/ .section-ink img {
  max-width: 1000px;
  filter: hue-rotate(230deg);
}
/deep/ #lead-enterprise .lead {
  max-width: 25em;
}
/deep/ .slide-logo {
  opacity: 1;
}
/deep/ .slide-logo img {
  width: 80px;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/

@media screen and (min-width: 768px) {
  /deep/ .slide-logo {
    bottom: 1.8em !important;
    right: -0.5em !important;
  }
}

@media screen and (max-width: 1600px) {
  .row-2 {
    margin-left: 2%;
  }
  .section-ink {
    margin-right: 22%;
    margin-top: 10%;
  }
  /deep/ .slide-text {
    padding-left: 4em;
  }
}

@media screen and (max-width: 1440px) {
  .timeline {
    margin-top: -0.5rem;
  }
  .col-1 {
    padding-right: 1.5rem;
  }
  .section-ink {
    margin-right: 25%;
    margin-top: 11%;
  }
}

@media screen and (max-width: 1366px) {
}

@media screen and (max-width: 1330px) {
  .row-2 {
    margin: 0;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
  .col-1 {
    padding: 0;
    width: 100%;
  }
  .col-2 {
    padding: var(--col-padding);
    align-items: flex-start;
    max-width: 56.5em;
    margin-bottom: 3rem;
  }
  #lead-boxslider {
    margin-left: 0;
  }
  .timeline {
    margin-top: 0;
  }
  .rows {
    margin-left: 10%;
  }
  .section-title {
    margin-right: 10rem;
  }
  /deep/ #lead-enterprise .lead {
    max-width: 100%;
  }
  /deep/ .single-line.section-title > span {
    vertical-align: unset;
    margin: 0;
  }
  /deep/ .single-line.section-title br {
    display: block;
  }
  #diagram-enterprise {
    margin-top: 1.8em;
  }
  /deep/ #lead-boxslider .lead-heading {
    display: none !important;
  }
  /deep/ .slide-text {
    padding-left: 0;
  }
  .section-ink {
    margin-right: -9%;
    margin-top: -16%;
  }
}

@media screen and (max-width: 1200px) {
  /deep/ #man img {
    max-width: 380px;
  }
  .section-ink {
    margin-top: -19%;
  }
}

@media screen and (max-width: 1024px) {
  .section-ink {
    margin-top: -26%;
  }
}

@media screen and (max-width: 1023px) {
}

@media screen and (max-width: 991px) {
  .col-2 {
    max-width: 100%;
    margin-right: var(--col-padding-adjust);
    margin-left: 20%;
    justify-content: center;
    margin-bottom: 5rem;
  }
  /deep/ .glide__bullets {
    padding-top: 0;
  }
  .timeline {
    margin-bottom: 1.5rem;
  }
  .rows {
    margin-left: 0;
  }
  #lead-boxslider {
    margin-right: 0;
    padding: var(--col-padding);
  }
  .section-title {
    margin-right: var(--col-padding-adjust);
  }
  /deep/ #lead-enterprise .lead {
    max-width: 25em;
  }
  /deep/ .slide-text {
    padding-left: 4em;
  }
  .section-ink {
    margin-right: -4%;
    margin-top: -38%;
  }
  .ink-container {
    width: 120%;
  }
}
@media screen and (max-width: 840px) {
  .section-ink {
    margin-top: -44%;
  }
}
@media screen and (max-width: 767px) {
  /deep/ .glide__bullets {
    padding: 1.5rem 0 0.5rem;
  }
  #man {
    left: -3.5rem;
    bottom: -0.8rem;
  }
  .ink-container {
    width: 110%;
  }
  .section-ink {
    margin-top: -50%;
  }
}
@media screen and (max-width: 680px) {
  /deep/ #man img {
    max-width: 300px;
  }
  /deep/ .slide-text {
    padding-left: 0;
  }
}

@media screen and (max-width: 639px) {
  #zebras {
    bottom: -0.5rem;
  }
  /deep/ #zebras img {
    min-width: 1000px;
  }
  .section-ink {
    margin-top: -56%;
  }
}
@media screen and (max-width: 580px) {
  /deep/ #man img {
    max-width: 250px;
  }
  .col-2 {
    align-items: flex-end;
    margin-left: 0;
    align-self: flex-end;
  }
}
@media screen and (max-width: 560px) {
  .ink-container {
    width: 120%;
  }
}
@media screen and (max-width: 520px) {
  .timeline {
    padding-bottom: 0.8rem;
  }
  #diagram-enterprise {
    margin-top: 1.5em;
  }
  .col-2 {
    margin-left: 25%;
  }
  #man {
    left: -5rem;
  }
  .section-ink {
    margin-top: -66%;
  }
}

@media screen and (max-width: 479px) {
  .section-ink {
    margin-top: -78%;
  }
  .ink-container {
    width: 125%;
  }
}

@media screen and (max-width: 420px) {
  .section-ink {
    margin-top: -80%;
  }
  .ink-container {
    width: 125%;
  }
}

@media screen and (max-width: 390px) {
  /deep/ #man img {
    max-width: 200px;
  }
  .section-ink {
    margin-top: -90%;
  }
  .ink-container {
    width: 140%;
  }
  .portrait #man {
    bottom: -1.5rem;
  }
}
@media screen and (max-width: 370px) {
  /deep/ #man img {
    max-width: 200px;
  }
  .section-ink {
    margin-top: -100%;
  }
  .ink-container {
    width: 140%;
  }
}

@media screen and (max-height: 620px) {
  .portrait #man {
    bottom: -6%;
  }
}
@media screen and (max-height: 610px) {
  .portrait #man {
    bottom: -19%;
  }
}
</style>
